var site = site || {};

(function($) {

site.multiQS = {

  startAndProcessFirstSku:  function(context) {
    $('.button_both', context).unbind().on('click', function(evt){
      evt.preventDefault();
      var both_btn = evt.target;
      var qs_arr = [];
      $(both_btn).parents('.row-products').find('.product[data-productid]').each(function(j, prod_div){
        var sku, undef;
        var prodId  = $(prod_div).attr('data-productid');
        var product = page_data['product-input-type'].products.filter(function(p) {
          return (p !== null) ? (p.PRODUCT_ID === prodId) && (p.skus !== undef) : false;
        })[0];

        // product not found in page_data... check if it might be attached to any of the skus
        if (!product && page_data["product-input-type"].skus) {
          var skus = page_data["product-input-type"].skus.filter(function(p) {
            return p.PRODUCT_ID === prodId;
          });
          if (skus && skus[0].product) {
            product = skus[0].product;
            product.skus = skus;
          }
        }

        var count_valid = 0;
        
        for (var i = 0; i < product.skus.length; i++){
          var o_sku = $(document).data(product.skus[i].SKU_ID);
          if((o_sku !== undef) && ((o_sku.isOrderable == 1) || (o_sku.INVENTORY_STATUS == 3))){
            if(($(this).attr('data-skuid') === undef) || ($(this).attr('data-skuid') === product.skus[i].SKU_ID)) { // added attribute means "use this sku!"
              if(sku === undef) {
                sku = o_sku;
              }
              count_valid++;
            }
          }
        }

        qs_arr[j] = {
          product_data: product,
          valid_skus: count_valid,
          first_valid_sku:  sku
        }
      });

      if(qs_arr[0].valid_skus > 1){
        var $qs = site.quickshop.render({ productData : qs_arr[0].product_data, showReviews: false });
        site.multiQS.processQS($qs);
        $(document).on('multi-quick', function(k, data){
          $(both_btn).attr('sku_0', data['skuBaseId']);
          $(document).unbind('multi-quick');
          $('#foreground-node .close-link').trigger('click');
          generic.overlay.hide();
          site.multiQS.processSecondSku(qs_arr, both_btn);
        });
      } else {
        $(both_btn).attr('sku_0', qs_arr[0].first_valid_sku.SKU_BASE_ID);
        site.multiQS.processSecondSku(qs_arr, both_btn);
      }

    });
  },
  startAndProcessFirstSkuSPP:  function(context, spp_product, couple_product) {
    $('.add-both-to-bag.button', context).on('click', function(evt){
      evt.preventDefault();
      var both_btn = evt.target;
      var qs_arr = [];
      jQuery.each([spp_product, couple_product], function(j, product){
        var count_valid = 0;
        var sku, undef;
        for (var i = 0; i < product.skus.length; i++){
          var o_sku = $(document).data(product.skus[i].SKU_ID);
          if((o_sku !== undef) && ((o_sku.isOrderable == 1) || (o_sku.INVENTORY_STATUS == 3))){
            if(sku === undef) {
              sku = o_sku;
            }
            count_valid++;
          }
        }

        qs_arr[j] = {
          product_data: product,
          valid_skus: count_valid,
          first_valid_sku:  sku
        }
      });


      if(qs_arr[0].valid_skus > 1){
        var $qs = site.quickshop.render({ productData : qs_arr[0].product_data, showReviews: false });
        site.multiQS.processQS($qs);
        $(document).on('multi-quick', function(k, data){
          $(both_btn).attr('sku_0', data['skuBaseId']);
          $(document).unbind('multi-quick');
          $('#foreground-node .close-link').trigger('click');
          generic.overlay.hide();
          site.multiQS.processSecondSku(qs_arr, both_btn);
        });
      } else {
        $(this).attr('sku_0', qs_arr[0].first_valid_sku.SKU_BASE_ID);
        site.multiQS.processSecondSku(qs_arr, both_btn);
      }

    });
  },
  processSecondSku: function(qs_arr, both_btn){
    if(qs_arr[1].valid_skus > 1){
      var $qs = site.quickshop.render({ productData : qs_arr[1].product_data, showReviews: false });
      site.multiQS.processQS($qs);
      $(document).on('multi-quick', function(k, data){
        $(both_btn).attr('sku_1', data['skuBaseId']);
        $(document).unbind('multi-quick');
        $('#foreground-node .close-link').trigger('click');
        generic.overlay.hide();
        site.addToCart({
          skuBaseId: [$(both_btn).attr('sku_0'), $(both_btn).attr('sku_1')]
        });
      });
    } else {
      $('#foreground-node .close-link').trigger('click');
      generic.overlay.hide();
      $(both_btn).attr('sku_1', qs_arr[1].first_valid_sku.SKU_BASE_ID);
      site.addToCart({
        skuBaseId: [$(both_btn).attr('sku_0'), $(both_btn).attr('sku_1')]
      });
    }
  },
  processQS: function($qs) {
    $qs.css('display', 'block').find('.close').hide();
    $('.add-to-bag', $qs).addClass('multi-quickshop');
    var select_type = ($('.shades', $qs).length === 0) ?
                      (($('.skin-type.hidden', $qs).length === 0) ? 'skin_type' : 'size')
                      : 'shade' ;
    $('.select_type', $qs)
      .html($('span[data-localtrans="'+select_type+'"]').html())
      .parent().removeClass('hidden');
    $('.col2.review', $qs).remove(); // remove review column
    $('.col1', $qs).first().css('width', '100%'); // set main column to full width
    generic.overlay.launch({
      content: $qs,
      includeBackground: true,
      hideOnClickOutside : true,
      includeCloseLink : true // cssStyle: {width: '700px', height: 'auto'}
    });
  }
};

})(jQuery);